<template>
  <div class="homeDemo" :style="{ height: isFold ? '38px' : 'auto' }">
    
     
    <!-- <div class="titleOut">体育投注</div> -->
    <div class="fold"  ref="scrollTarget">
      <div class="foldLeft">
        <img :src="icon" />
        {{ name }}
      </div>

      <div @click.stop="$router.push('/sport')" class="lazyImg">
        <!-- <img   v-if="name == '体育'" src="@/assets/home/tab/sportSmall0.webp" /> -->
      </div>
      

      <div class="foldRight">
        <!-- <img
          src="@/assets/home/arrow.svg"
          :style="{ transform: isFold ? 'rotate(180deg)' : 'rotate(0deg)' }"
        /> -->
      </div>
    </div>

   

    <div class="smailOut"  :class="{ zerenOut: name == '真人' }">
      <div
        class="smailList"
        v-for="(item, index) in list"
        :key="index"
        @click="goPage(item)"
        :class="{ caicaic: name == '彩票', zeren: name == '真人' }"
        :style="{
          backgroundImage: `url(${item.img})`,
          backgroundSize: '100% 100%',
        }"
        v-show="item.status != 2"
      >
        <!-- <div class="smailBj" :style="{'backgroundImage': `url(${item.image})` }"> -->
        <div class="smailMaintainDemo" v-show="item.status != 0">
          <img src="@/assets/home/tab/homeWhz.svg" />{{
            item.status | getStatus
          }}
        </div>
        <!-- </div> -->
      </div>
    </div>
    
    <div class="tiaoOut"><div class="tiao" :style="{width: (list.length/total)*100+'%' }"></div></div>
    <div class="dibTip">{{ `正在显示 ${total} 款游戏中的 ${list.length} 个` }}</div>
    <van-button class="jzgd-btn" :loading="loading" @click="jzgd()" v-show="totalPage>pageNumber"
        >加载更多</van-button
      >
  </div>
</template>

<script>
import { pdgameLaunch, platformLogin,platformSlot } from "@/request/api";
export default {
  name: "sportsDemo",
  props: [ "name", "icon", "tabs",'code','upName',"id"],
  data() {
    return {
      isFold: false,
      list:[],
      pageNumber:1,
      loading:false,
      totalPage:1,
      total:0,
      // id:614,//pg:614
    };
  },
  mounted(){
    this.getPlatformSlot();
  },
  methods: {
    jzgd(){
      this.loading = true;
      this.pageNumber++;
      this.getPlatformSlot();
    },
    getPlatformSlot(){
      this.loading = true;
      var opt ={
        id:this.id,
                // code: this.code,//pg
                tp:"SLOT",
                pageSize:18,
                pageNumber:this.pageNumber,
      }
      platformSlot(opt).then((res)=>{
        if(res.code == 0){
          this.list.push(...res.data.data)
          this.totalPage = res.data.totalPage
          this.total = res.data.total
        }
        this.loading = false;
      })
    },
    setFold() {
      this.isFold = false;
    },
    goPage(item) {
      if (item.status != 0) {
        return;
      }
      
        if (localStorage.getItem("userYou")&&localStorage.getItem("userYou") == "tourists") {
          this.$notify({
            message: "请登录后查看",
            duration: 2000,
            background: "#FF4337",
          });
          return;
        }
        
        var regex = /we/i;
        if (!regex.test(this.upName)) {
          this.$store.commit("setLoadingShowTrue");
      platformLogin({ code: item.gameCode, id: this.id }).then((res) => {
        if (res.code == 0) {
          this.$store.commit("setUrl", res.data.url);
          // this.$router.push({ path: "/block",query:{'upList':JSON.stringify(this.upList),id:this.id,'name':this.blackName,'link':'/',"tabs":this.tabs,url:res.data.url} });
          window.open(res.data.url, "_blank")
          setTimeout(() => {
            this.$store.commit("setLoadingShowFalse");
          }, 5000);
        } else {
          this.$store.commit("setLoadingShowFalse");
        }
      });
    }else{
      this.oldSports(item);
    }
    },

    oldSports(item) {
      var opt = {
        code:this.code,
        gameId:item.id
      }
      //到这里就是名字有pd的 或者数量只有一个的   还有一个saba
      this.$store.commit("setLoadingShowTrue");

      pdgameLaunch(opt).then((res) => {
        if (res.code == 0) {
          // window.location.href = res.data
          this.$store.commit("setUrl", res.data);
          window.open(res.data, "_blank")
          setTimeout(() => {
            this.$store.commit("setLoadingShowFalse");
          }, 5000);
        } else {
          this.$store.commit("setLoadingShowFalse");
        }
      });
    },

    goPages(index) {
      if (this.list[index].status != 0) {
        return;
      }
      var regex = /we/i;
      if (!regex.test(this.list[index].name)) {
        if (this.list[index].gameCount > 0) {
          this.$router.push({
            path: "/classIndex",
            query: { list: JSON.stringify(this.list[index]) },
          });
          return;
        }
      }
      this.$store.commit("setLoadingShowTrue");
      var opt = {
        code: this.list[index].code,
      };
      pdgameLaunch(opt).then((res) => {
        if (res.code == 0) {
          // window.location.href = res.data
          this.$store.commit("setUrl", res.data);
          // if(!regex.test(this.list[index].name)){
          //     this.$router.push({path:'/block'})
          // }else{
          //     this.$router.push({path:'/sports'})
          // }
          this.$router.push({ path: "/block" });
          setTimeout(() => {
            this.$store.commit("setLoadingShowFalse");
          }, 5000);
        } else {
          this.$store.commit("setLoadingShowFalse");
        }
      });
    },
  },
  filters: {
    getStatus(status) {
      switch (status) {
        case 0:
          return "";
        case 1:
          return "维护中";
        case 2:
          return "已停用";
        default:
          return "";
      }
    },
  },

};
</script>

<style lang="less" scoped>
.homeDemo {
  transition: all 0.5s ease;
  overflow: hidden;
  position: relative;
  // margin-bottom: 8px;
  .fold {
    width: 100%;
    margin: 24px auto 24px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // height: 38px;
    transition: all 0.5s ease;
    position: relative;
   
    .foldLeft {
      display: flex;
      align-items: center;
      color: #fff;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;

      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }

    .foldRight {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        transition: all 0.5s ease;
      }
    }
  }

  .titleOut {
    color: #fff;
    font-family: "PingFang SC";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 27px */
    text-align: left;
    width: 89.3333%;
    margin: 20px auto 0 auto;
  }


  .widthDemo {
    width: 100%;
    height: 130px;
    border-radius: 16px;
    overflow: hidden;
    margin: 0px auto 10px auto;
    padding: 19px 20px;
    position: relative;

    background: radial-gradient(
      114.24% 141.42% at 0% 0%,
      rgba(155, 155, 186, 0.16) 31.69%,
      rgba(155, 155, 186, 0.04) 100%
    );

    .maintainDemo {
      width: 138.5px;
      height: 100%;
      position: absolute;
      background-image: url("@/assets/home/tab/home-mark.svg");
      background-size: cover;
      border-radius: 0 0 16px 0;
      right: -0.5px;
      bottom: 0;
      margin: auto;
      z-index: 3;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      color: #fff;
      padding: 0 10px 12px 0;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;

      img {
        width: 28px;
        height: 28px;
        margin-right: 10px;
      }
    }

    .tips {
      color: rgba(255, 255, 255, 0.65);
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      text-align: left;
    }

    .title {
      margin-top: 14px;
      color: #fff;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      /* 24px */
      text-align: left;
      display: flex;
      align-items: flex-end;

      .titLeft {
        color: #fff;
        font-family: "FONTSPRING DEMO - Integral CF Demi Bold";
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 95%;
        /* 30px */
      }

      .titRight {
        display: inline-flex;
        padding: 0 12px;
        height: 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #fff;
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        border-radius: 16px;
        background: rgba(255, 255, 255, 0.15);
        margin-left: 8px;
      }
    }

    .titTwo {
      margin-top: 10px;
      /* 18px */
      text-align: left;

      color: #fff;
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }

    .inbtn {
      display: flex;
      width: 88px;
      height: 32px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      flex-shrink: 0;
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.15);
      color: var(---100, #fff);
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-top: 12px;
    }
  }

  .smailOut {
    display: grid;

    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 172px);
    grid-gap: 15px;
    width: 100%;
    margin: 0px auto 0 auto;
    flex-wrap: wrap;
    .smailList {
      width: 172px;
      height: 211px;
      border-radius: 12px;

      background: radial-gradient(
        114.24% 141.42% at 0% 0%,
        rgba(155, 155, 186, 0.16) 31.69%,
        rgba(155, 155, 186, 0.04) 100%
      );
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;
      .smailBj {
        width: 100%;
        height: 100%;
        border-radius: 12px;
      }
      .smailMaintainDemo {
        width: 100%;
        height: 70px;
        position: absolute;
        background: linear-gradient(
          180deg,
          rgba(16, 27, 31, 0) 0%,
          rgba(16, 27, 31, 0.75) 66%
        );
        background-size: cover;
        border-radius: 0 0 12px 12px;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 3;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        color: #fff;
        padding: 0 10px 12px 0;
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;

        img {
          width: 28px;
          height: 28px;
          margin-right: 10px;
        }
      }
    }
    .caicaic {
      width: 115px !important;
      height: 177px !important;
    }

    .zeren {
      width: 165px !important;
      height: 130px !important;
    }
  }
  .zerenOut {
    grid-template-columns: repeat(2, 165px) !important;
    grid-gap: 15px;
  }
}

.my-swipeTwo {
  width: 138px;
  height: 28px;
  position: absolute;
  top: 6px;

  left: 0;
  right: 0;
  margin: auto;
  z-index: 4;
  // border-radius: 20px;
  .lazyImg {
    width: 138px;
    height: 28px;
  }
}
.lazyImg {
    width: 138px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 2px;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 4;
    img{
      width: 100%;
      height: 100%;

    }
  }

  .jzgd-btn{
    margin: 11px auto 0 auto;
    border: 0;
    border-radius: 6px;
    width: 168px;
    height: 40px;
    background: rgba(71, 116, 218, 1);
    color: #fff;
    font-weight: 400;
  font-size: 16px;
  }
  .tiaoOut{
    width: 168px;
    height: 4px;
    border-radius: 4px;
    background: rgba(155, 155, 186, 0.16);
    margin: 20px auto 0 auto;
    .tiao{
      width: 0;
      max-width: 100%;
      height: 4px;
  border-radius: 2px;
  background: linear-gradient(91.35deg, rgba(3, 89, 255, 1) 0%, rgba(3, 133, 255, 1) 58.42%, rgba(148, 255, 255, 1) 98.95%);
    }
  }
  .dibTip{
    margin: 11px auto 0 auto;
    color: rgba(179, 185, 210, 1);
    font-family: "PingFang SC";
  font-weight: 400;
  font-size: 14px;
  }
</style>
